import React from 'react'
import './css/Footer.css'
import { Link } from 'react-router-dom'
import logo1 from './WbB_LOGO.png'
import logo2 from '../images/Advanced_imports_logo.png'
import logo3 from '../images/WheelOne_logo.png'

function footer() {
    return (
        <div className='footer-container'>
                     <div className="footer-link-items">
                        <h2>Social Platorms</h2>
                        
                        <a className='social-icon-link' href ='https://www.facebook.com/Wheels-By-Bolo-108827408069089'
                        target="_blank"
                        aria-label="Facebook">
                            <i className='fab fa-facebook-f bluee'/> : Wheels By Bolo
                        </a>
                        <a className='social-icon-link' href ='https://www.instagram.com/wheelsbybolo_/'
                        target="_blank"
                        aria-label="Instagram">
                            <i className='fab fa-instagram'/> : wheelsbybolo_
                        </a>
                        <a className='social-icon-link' 
                        target="_blank"
                        aria-label="Gmail">
                            <i className='fab fa-google'/> : wheelsbybolo1@gmail.com
                        </a>
                    
                        
                    </div>

                    <div className="footer-logo">
                        <img src={logo1} />
                        <img src={logo2}  />
                        <img src={logo3}  />
                             
                    </div>
                        <small className = 'website-rights'>WbB 2021© 🇹🇹</small>
                    <small style={{fontSize:"1px"},{color:"rgba(14,14,14,.1"}}>Wheels by bolo, Wheels by bolo,Wheels by bolo, Wheels by bolo, 🇹🇹Wheels by bolo, Wheels by bolo, 🇹🇹Wheels by bolo, Wheels by bolo, 🇹🇹Wheels by bolo, Wheels by bolo, 🇹🇹Wheels by bolo, Wheels by bolo, 🇹🇹Wheels by bolo, Wheels by bolo, 🇹🇹 🇹🇹  🇹🇹</small>
     
        </div>

    )
}

export default footer
