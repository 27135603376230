import React,{useEffect, useState} from 'react';
import "../css/checkout.css";
import { useStateValue } from '../StateProvider';
import CheckoutProduct from '../CheckoutProduct';
import Subtotal from '../Subtotal';

function Checkout() {
    const [{basket, user}, dispatch]= useStateValue();

   

        return (
        <div className ='checkout'>
        
            <div className="ad_images">
                <img className='checkout__ad' src="https://scontent.fpos2-1.fna.fbcdn.net/v/t1.6435-9/p600x600/118731802_162798092094118_1297277264565442305_n.png?_nc_cat=108&ccb=1-5&_nc_sid=e3f864&_nc_ohc=79-5x7z3wW8AX8TxtHW&_nc_ht=scontent.fpos2-1.fna&oh=3e8303abb99abc00d87f7fbc1c067cc3&oe=616EF96F"/>
                <img className='checkout__ad' src="https://scontent.fpos2-1.fna.fbcdn.net/v/t1.6435-9/p180x540/185132622_115544433998563_6701796692270750357_n.jpg?_nc_cat=111&ccb=1-5&_nc_sid=e3f864&_nc_ohc=aZZefx3qLFAAX-w59Si&_nc_ht=scontent.fpos2-1.fna&oh=f3c6238fa5e95a792024f39dd01974d5&oe=6170CFB9"/>
        
            </div>
            
            <div>
                <h3>Hello, {user?.email}</h3>
                <p style={{fontFamily:"'Arial', sans-serif"}} >Disclaimer: Items in wishlist are not kept for customer, as its only purpose is for the customer to keep track of products they are interested in. To ensure you get the product you want, come down to our showroom where you can get the best deals on your desired item, while stocks last. </p>
                <h2 className="checkout__title">
                    Your Wishlist:
                </h2>
                {basket.map(item => (
                    <CheckoutProduct
                        id={item.id}
                        brand={item.brand}
                        boxnumber={item.boxnumber}
                        image={item.image}
                        price={item.price}
                        color={item.color}
                        size={item.size}
                        bolt_pattern={item.bolt_pattern}
                        offset={item.offset}
                        amount={item.amount}
                    />
                ))}
                
            </div>
          

            {/* <div className="checkout__right">
                <div className="sub">
                    
                <Subtotal/>

                </div>
               
                
            </div>
         */}
        
    </div>
)
}

export default Checkout
