import firebase from "firebase";
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAbBe3FJVExCBFPLG2nrcfXbr_H21I9Lvo",
    authDomain: "wheels-by-bolo.firebaseapp.com",
    projectId: "wheels-by-bolo",
    storageBucket: "wheels-by-bolo.appspot.com",
    messagingSenderId: "555740263893",
    appId: "1:555740263893:web:00c47d82fe14e8687ab95c",
    measurementId: "G-HWZJN7H99T"
  };

  const firebaseApp = firebase.initializeApp(firebaseConfig);

  const db = firebaseApp.firestore();
  const auth = firebase.auth();

  export {db, auth, firebaseApp};
  export const serverStamp= firebase.firestore.Timestamp;