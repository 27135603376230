import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Home from './components/pages/Home';
import Products from './components/Product_Section';
import Services from './components/pages/Services';
import Footer from './components/Footer';
import Rims from './components/pages/Rims';
import Tyres from './components/pages/Tyres';
import Login from './components/pages/Login';
import Upload from './components/pages/Upload';
import Upload2 from './components/pages/Upload2';
import {auth} from './components/firebase';
import {useStateValue} from "./components/StateProvider";
import Checkout from './components/pages/Checkout';
import CheckoutForm from './components/CheckoutForm';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollToTop from './components/ScrollToTop';
import Lights from './components/pages/Lights';
import Audio from './components/pages/Audio';
import Gauges from './components/pages/Gauges';
import VehicleParts from './components/pages/VehicleParts';
import Mufflers from './components/pages/Mufflers';
import Intake from './components/pages/Intake';
import Misc from './components/pages/Misc';
import OrdersAdmin from './components/pages/OrdersAdmin';
import Orders from './components/pages/Orders';
// import {useCookies} from "react-cookie";

function App() {
  
   const [ {basket,user}, dispatch] = useStateValue();
  //  const [cookies, setCookie] = useCookies(['basket']);
  //  const dispatch = useDispatch()
  //  useEffect(() => {
  //      console.log(cookies['basket'])
  //  },[basket]
  //  )

  useEffect(() => {
//will only run once when the app component loads
    auth.onAuthStateChanged(authUser =>{
      // console.log('USER IS >>>', authUser);

      if (authUser){
        //the user just logged in/ the user was logged in
        dispatch({
          type: 'SET_USER',
          user: authUser
        })
      }
      else{
        // the user is logged out
        dispatch({
          type: 'SET_USER',
          user: null
        })
      }
    })
  }, [])

  return (
    <div className="App">
      <Router>
        <ScrollToTop/>
        <Navbar/>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/Products" exact component={Products} />
          <Route path="/services" exact component={Services} />
          <Route path="/Rims" exact component={Rims} />
          <Route path="/Tyres" exact component={Tyres} />
          <Route path="/Login" exact component={Login} />
          <Route path="/Checkout" exact component={Checkout} />
          {/* <Route path="/CheckoutForm" exact component={CheckoutForm} /> */}
           {/* <Route path="/Upload" exact component={Upload} /> */}
         {/* <Route path="/Upload2" exact component={Upload2} /> */}
          <Route path="/Lights" exact component={Lights} />
          <Route path="/Audio" exact component={Audio} />
          <Route path="/Gauges" exact component={Gauges} />
          <Route path="/VehicleParts" exact component={VehicleParts} />
          <Route path="/Mufflers" exact component={Mufflers} />
          <Route path="/Intake" exact component={Intake} />
          <Route path="/Misc" exact component={Misc} />
          {/* <Route path="/OrdersAdmin" exact component={OrdersAdmin} /> */}
          {/* <Route path="/Orders" exact component={Orders} /> */}
        </Switch>
        <Footer/>
        
      </Router>
    </div>
  );
}

export default App;
