  import React, { useEffect, useState } from 'react'
import LandingPage from '../LandingPage';
import tyreGuide from '../../images/tyre-size-guide.png'
import '../css/Tyres.css';
import '../css/Rims.css';

import continental from "../../images/continental.jpg";
import delinte from "../../images/delinte.png";
import keter from "../../images/keter.png";
import mileking from "../../images/mileking.jpg";
import monsta from "../../images/monsta-wings2.png";
import nexen from "../../images/nexen-logo.png";
import pirelli from "../../images/pirelli.png";
import Roadstone from "../../images/Roadstone-logo.jpg";
import rovelo from "../../images/rovelo-logo.png";
import sumitomo from "../../images/sumitomo.jpg";
import sunny from "../../images/sunny.png";
import { db } from '../firebase';
import TyreItem from '../TyreItem';
import { ToastContainer } from 'react-toastify';
import Collapsible from 'react-collapsible';

function Tyres() {
    const [Products, setProducts] = React.useState([])
    const [lastDoc, setLastDoc] = useState();
    const [listoftyres, setListOfTyres] = useState([]);
    const [display_items, setDisplay_items] = useState('All');
    const [search_size, setSearch]= useState('');
    const [results_amt, setResults_amt]=useState('');

    const FetchBrand =(brand) => {
        setDisplay_items(brand)
          console.log("brand filter running")
          db.collection('Tyres').limit(15).where('brand','>=',brand).where("brand", "<=", brand + "\uf8ff").onSnapshot(ProductsCollection =>
            setProducts(ProductsCollection.docs.map(doc => doc.data())
            ))
    
       
       }
   
       
   const FetchSize = (size) => {
  

    //this fetches all rim items for choosen size.
    setDisplay_items(size + '"')
  
       db.collection('Tyres').where('Rim_size','==',size ).onSnapshot(ProductsCollection =>
        setProducts(ProductsCollection.docs.map(doc => doc.data())
        ,setResults_amt(ProductsCollection.docs.length + " items:")

        ))           
   }
    useEffect(() => {

        //this fetches all rim items.
        setDisplay_items('ALL')
          console.log("showing everything")
           db.collection('Tyres').limit(15).get().then((ProductsCollection) =>
            setProducts(ProductsCollection.docs.map(doc => doc.data())
            ))
               
       }, [])

    //****************************Search Function************************************************ */
    const Search = ()=> {
        console.log("searching for " + search_size )
           
            setDisplay_items(search_size + '"')
                         db.collection('Tyres').where('size','>=',search_size ).where('size','<=',search_size).onSnapshot(ProductsCollection =>
                setProducts(ProductsCollection.docs.map(doc => doc.data())
                
                ,setResults_amt(ProductsCollection.docs.length + " items:")
                ))

                console.log(results_amt)
    }

    //******************************************************************************************* */

       const [toggleState, setToggleState] = useState(1);

       const toggleTab = (index) => {
           setToggleState(index);
       }; 
   
    return (
        < div className="tyre_container">
        
               {/* <h1>Tyres</h1> */}
               {/* <p>Please select which type of tyres you would like</p> */}
           
            
        <div className="brand__selection">
            
            <img className="brand_logo" src={pirelli}></img>
            <img className="brand_logo-nexen" src={nexen}></img>
            <img className="brand_logo" src={mileking}></img>
            <img className="brand_logo" src={keter}></img>
            <h1>Tyres</h1>
            <img className="brand_logo" src={monsta}></img>
            <img className="brand_logo" src={continental}></img>
            <img className="brand_logo" src={sumitomo}></img>
            <img className="brand_logo" src={Roadstone}></img>
            {/* <img className="brand_logo" src={delinte} ></img> */}
            {/* <img className="brand_logo-" src={rovelo}></img> */}
            {/* <img className="brand_logo" src={sunny} ></img> */}
            {/* <img className="brand_logo" src={nexen}></img> */}
           
        </div>    
           <div className="main_display">
           <div class="tyre_left">
           <div className="tab_filter">
               <Collapsible className="filter_btn" trigger="_____________Filter_____________">
            <div className="container">
                
                <div className="bloc-tabs">
                    <button
                        className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleTab(1)}>
                        Rim size
                    </button>
                    
                    <button
                        className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleTab(2)}>
                        Brand
                     </button>
                    {/* <button
                        className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleTab(3)}
                    >
                        Size
                    </button> */}
                </div>

                <div className="content-tabs">
                    {/* <div className={toggleState === 1 ? "content  active-content" : "content"}> */}
                        {/* <h2>Filter by color</h2>
                        <hr />
                        <ul className="list_section">
                       
                        <li onClick={() => FetchColor('WHITE')} ><i class="fas fa-square fa-2x white"></i>  White</li>
                    <li onClick={() => FetchColor('GOLD')} ><i class="fas fa-square fa-2x gold"></i>  Gold</li>
                    <li onClick={() => FetchColor('SILVER')} ><i class="fas fa-square fa-2x silver"></i>  Silver</li>
                    <li onClick={() => FetchColor('MATTE SILVER')} ><i class="fas fa-square fa-2x silver"></i> MATTE Silver</li>

                    <li onClick={() => FetchColor('GREEN')} ><i class="fas fa-square fa-2x green"></i>  Green</li>
                    <li onClick={() => FetchColor('BLUE')} ><i class="fas fa-square fa-2x bluee"></i>  Blue</li>
                    <li onClick={() => FetchColor('RED')} ><i class="fas fa-square fa-2x red"></i>  Red</li>
                    <li onClick={() => FetchColor('PINK')} ><i class="fas fa-square fa-2x pink"></i>    Pink</li>
                    <li onClick={() => FetchColor('PURPLE')} ><i class="fas fa-square fa-2x purple"></i>    Purple</li>

                    <li onClick={() => FetchColor('BRONZE')} ><i class="fas fa-square fa-2x bronze"></i>  Bronze</li>
                    <li onClick={() => FetchColor('GREY')} ><i class="fas fa-square fa-2x grey"></i>  Grey</li>
                    <li onClick={() => FetchColor('MATTE GREY')} ><i class="fas fa-square fa-2x grey"></i> MATTE Grey</li>

                    <li onClick={() => FetchColor('GUN METAL')} ><i class="fas fa-square fa-2x grey"></i>  Gun metal</li>
                    <li onClick={() => FetchColor('MATTE GUN METAL')} ><i class="fas fa-square fa-2x grey"></i>  MATTE Gun metal</li>


                    <li onClick={() => FetchColor('YELLOW')} ><i class="fas fa-square fa-2x yellow"></i>  Yellow</li>
                    <li onClick={() => FetchColor('ORANGE')} ><i class="fas fa-square fa-2x orange"></i>  Orange</li>
                    <li onClick={() => FetchColor('CHROME')} ><i class="fas fa-square fa-2x silver"></i>  Chrome</li>

                    <li onClick={() => FetchColor('BLACK')} ><i class="fas fa-square fa-2x black" ></i>  Black</li>
                    <li onClick={() => FetchColor('MATTE BLACK')} ><i class="fas fa-square fa-2x black" ></i> Matte Black</li>
                    <li onClick={() => FetchColor('BLACK CHROME')} ><i class="fas fa-square fa-2x black"></i>  Black Chrome</li>
                    <li onClick={() => FetchColor('BLACK AND SILVER')} ><i class="fas fa-square fa-2x black" ></i>  Black and silver</li>
                    <li onClick={() => FetchColor('BLACK AND CHROME')} ><i class="fas fa-square fa-2x black" ></i>  Black and chrome</li>
                    <li onClick={() => FetchColor('BLACK AND BLUE')} ><i class="fas fa-square fa-2x black" ></i>  Black and blue</li>
                    <li onClick={() => FetchColor('BLACK AND RED')} ><i class="fas fa-square fa-2x black" ></i>  Black and red</li>


                </ul>  */}
                {/* <center><button className= 'ShowALLButton_sm' onClick={()=>fetchAllProducts()}> View All </button></center> */}
          
                              {/* </div>  */}

                <div className={toggleState === 2 ? "content  active-content" : "content"}>
                    <h2>Filter by Brand</h2>
                    <hr />
                    <ul className="list_section_brands_t">
                    <li onClick={() => FetchBrand('A')}>A</li>    <li onClick={() => FetchBrand('B')}>B</li>    <li onClick={() => FetchBrand('C')}>C</li> <li onClick={() => FetchBrand('D')}>D</li> <li onClick={() => FetchBrand('E')}>E</li>
                    <li onClick={() => FetchBrand('F')}>F</li>    <li onClick={() => FetchBrand('G')}>G</li>    <li onClick={() => FetchBrand('H')}>H</li> <li onClick={() => FetchBrand('I')}>I</li> <li onClick={() => FetchBrand('J')}>J</li>
                    <li onClick={() => FetchBrand('K')}>K</li>    <li onClick={() => FetchBrand('L')}>L</li>    <li onClick={() => FetchBrand('M')}>M</li> <li onClick={() => FetchBrand('N')}>N</li> <li onClick={() => FetchBrand('O')}>O</li>
                    <li onClick={() => FetchBrand('P')}>P</li>    <li onClick={() => FetchBrand('Q')}>Q</li>    <li onClick={() => FetchBrand('R')}>R</li> <li onClick={() => FetchBrand('S')}>S</li> <li onClick={() => FetchBrand('T')}>T</li>
                    <li onClick={() => FetchBrand('U')}>U</li>    <li onClick={() => FetchBrand('V')}>V</li>    <li onClick={() => FetchBrand('W')}>W</li> <li onClick={() => FetchBrand('X')}>X</li> <li onClick={() => FetchBrand('Y')}>Y</li>
                    <li onClick={() => FetchBrand('Z')}>Z</li>


                </ul>
                {/* <center><button className= 'ShowALLButton_sm' onClick={()=>fetchAllProducts()}> View All </button></center> */}
               
                </div>

                <div className={toggleState === 1 ? "content  active-content" : "content"} >
                    <h2>Filter by Size</h2>
                    <hr />
                    <ul className="tab_size">
                    <li onClick={() => FetchSize('12')}>12"</li>    <li onClick={() => FetchSize('13')}>13"</li>     <li onClick={() => FetchSize('14')}>14"</li>    
                    <li onClick={() => FetchSize('15')}>15"</li>    <li onClick={() => FetchSize('16')}>16"</li>     <li onClick={() => FetchSize('17')}>17"</li>    
                    <li onClick={() => FetchSize('18')}>18"</li>     <li onClick={() => FetchSize('19')}>19"</li>    <li onClick={() => FetchSize('20')}>20"</li>
                    <li onClick={() => FetchSize('21')}>21"</li>     <li onClick={() => FetchSize('22')}>22"</li>    <li onClick={() => FetchSize('23')}>23"</li>    
                    <li onClick={() => FetchSize('24')}>24"</li>     <li onClick={() => FetchSize('25')}>25"</li>    <li onClick={() => FetchSize('26')}>26"</li>    
                    <li onClick={() => FetchSize('27')}>27"</li>     <li onClick={() => FetchSize('28')}>28"</li>    <li onClick={() => FetchSize('29')}>29"</li>    
                    <li onClick={() => FetchSize('30')}>30"</li>     <li onClick={() => FetchSize('31')}>31"</li>    <li onClick={() => FetchSize('32')}>32"</li>    
                    <li onClick={() => FetchSize('33')}>33"</li>  
                    
                </ul>
               
                {/* <center><button className= 'ShowALLButton_sm' onClick={()=>fetchAllProducts()}> View All </button></center> */}
                </div>

    </div>
  
  </div>
  </Collapsible>
            </div>
           <div className="brand_filter_section">
               <img src= {tyreGuide} className="tyreGuide"/>
               {/* <h2 className="filter">Filter by Width</h2>
                     */}
               
               <h2 className="filter">Filter by Brand</h2>
                    <ul className="list_section_brands">
                    <li onClick={() => FetchBrand('A')}>A</li>    <li onClick={() => FetchBrand('B')}>B</li>    <li onClick={() => FetchBrand('C')}>C</li> <li onClick={() => FetchBrand('D')}>D</li> <li onClick={() => FetchBrand('E')}>E</li>
                    <li onClick={() => FetchBrand('F')}>F</li>    <li onClick={() => FetchBrand('G')}>G</li>    <li onClick={() => FetchBrand('H')}>H</li> <li onClick={() => FetchBrand('I')}>I</li> <li onClick={() => FetchBrand('J')}>J</li>
                    <li onClick={() => FetchBrand('K')}>K</li>    <li onClick={() => FetchBrand('L')}>L</li>    <li onClick={() => FetchBrand('M')}>M</li> <li onClick={() => FetchBrand('N')}>N</li> <li onClick={() => FetchBrand('O')}>O</li>
                    <li onClick={() => FetchBrand('P')}>P</li>    <li onClick={() => FetchBrand('Q')}>Q</li>    <li onClick={() => FetchBrand('R')}>R</li> <li onClick={() => FetchBrand('S')}>S</li> <li onClick={() => FetchBrand('T')}>T</li>
                    <li onClick={() => FetchBrand('U')}>U</li>    <li onClick={() => FetchBrand('V')}>V</li>    <li onClick={() => FetchBrand('W')}>W</li> <li onClick={() => FetchBrand('X')}>X</li> <li onClick={() => FetchBrand('Y')}>Y</li>
                    <li onClick={() => FetchBrand('Z')}>Z</li>


                    
                 </ul>
                 <h2 className="filter">Filter by Size</h2>
                <ul className="list_section_size">
                    <li onClick={() => FetchSize('12')}>12"</li>    <li onClick={() => FetchSize('13')}>13"</li>     <li onClick={() => FetchSize('14')}>14"</li>    
                    <li onClick={() => FetchSize('15')}>15"</li>    <li onClick={() => FetchSize('16')}>16"</li>     <li onClick={() => FetchSize('17')}>17"</li>    
                    <li onClick={() => FetchSize('18')}>18"</li>     <li onClick={() => FetchSize('19')}>19"</li>    <li onClick={() => FetchSize('20')}>20"</li>
                    <li onClick={() => FetchSize('21')}>21"</li>     <li onClick={() => FetchSize('22')}>22"</li>    <li onClick={() => FetchSize('23')}>23"</li>    
                    <li onClick={() => FetchSize('24')}>24"</li>     <li onClick={() => FetchSize('25')}>25"</li>    <li onClick={() => FetchSize('26')}>26"</li>    
                    <li onClick={() => FetchSize('27')}>27"</li>     <li onClick={() => FetchSize('28')}>28"</li>    <li onClick={() => FetchSize('29')}>29"</li>    
                    <li onClick={() => FetchSize('30')}>30"</li>     <li onClick={() => FetchSize('31')}>31"</li>    <li onClick={() => FetchSize('32')}>32"</li>    
                    <li onClick={() => FetchSize('33')}>33"</li>    
   
                </ul>
               {/* <h2 className="filter">Filter by Profile</h2>
                   <ul className="list_section_size">
                       <li>14"</li>    <li>15"</li>     <li>16"</li>
                       <li>17"</li>    <li>18"</li>     <li>19"</li>
                       <li>20"</li>    <li>21"</li>     <li>22"</li>
                       <li>23"</li>    <li>24"</li>     <li>25"</li>
                       <li>26"</li>    <li>27"</li>     <li>28"</li>
                       <li>29"</li>    <li>30"</li>     <li>31"</li>
                       <li>32"</li>    <li>33"</li>
                       
                   </ul> */}
               </div>
           </div>
           
           <div className="tyre_right">
             <center>
               
             <div class="wrap">
                <div class="search">
                        <input type="text" class="searchTerm" name="search_size" placeholder="205/60/15" 
                            onChange={(e) => { setSearch(e.target.value)}}/>


                        <button type="submit" class="searchButton" onClick={Search}>
                            <i class="fa fa-search"></i>
                        </button>
                </div>
                        <p>Please enter the size you want as shown in the search bar.</p>
            </div>
</center>
               <h2 className="display_items">Showing {display_items} Tyres</h2>
               <div className="tyre_display">
           
        <ul className="tyre_display">
        <center><h4 > {results_amt} </h4></center>

                    {Products.map((Product) => {
                        return (
                                   <li className="list">
                        
                                        <TyreItem
                                            id={Product.id}
                                            // src={Product.image}
                                            brand={Product.brand}
                                            size={Product.size}
                                            beading={Product.beading}
                                            cond={Product.cond}
                                            amount={Product.amount}
                                          />  

                                   </li>
                                );
                         })}
            </ul>
               </div>
   
           </div>
           </div>
           <ToastContainer/>
        </div>
               
               
               
           
       );
}

export default Tyres
