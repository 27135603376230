import React, {useState, useEffect} from 'react';
import { useStateValue } from './StateProvider';
import {Link, useHistory} from 'react-router-dom';
import './css/Navbar.css'
import logo1 from './WbB_LOGO.png'
import logo2 from '../images/Advanced_imports_logo.png'
import logo3 from '../images/WheelOne_logo.png'
import { auth } from './firebase';

export const basket_ls = JSON.parse(localStorage.getItem("cart") || '[]')
localStorage.setItem("cart", '[]' ); 

function Navbar() {

    const[{basket, user}, dispatch] = useStateValue();
    
    

        const basket_1 = JSON.stringify(basket)
        useEffect(() =>{
        
               localStorage.setItem("cart", basket_1 ); 
             
        },[basket])
  
        
  

        // console.log(basket)

        const handleAuthentication=() =>{
        if (user){
            auth.signOut();
        }
    }

    const [click, setClick] = useState(false);
    const history =useHistory();
    const handleClick= () => setClick(!click);
    const closeMobileMenu = () => setClick(false);


 
    return (
        <>
        <nav className="navbar">
            <div className="navbar-container">
                
                    <div className = "navbar-logo" >
                  <img className="nav_logo" src={logo1}  onClick={e => history.push('/')} />
                        <img className="nav_logo2" src={logo2}  />
                        <img className="nav_logo3" src={logo3}  />
                        </div>
                 
                <div className="menu-icon1" >
                
                        <div onClick={e => history.push('/checkout')} className="header__optionBasket nav-cart">
                                <i class="fas fa-shopping-cart fa-xs Greene"></i>
                            <span className="header__optionLineTwo header__basketCount">{basket?.length}</span>
                            </div>
                            <i onClick={handleClick} className={click ? 'fas fa-times' : 'fas fa-bars  '}/>
                        
                    
                </div>
                </div>
        </nav>
                {/* <div className="menu-icon" >
                
                    <i onClick={handleClick} className={click ? 'fas fa-times fa-7x' : 'fas fa-bars '}/>
                </div> */}
                <div className="nav_options">
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to ='/' className='nav-links' onClick={closeMobileMenu}>
                            <i class="fas fa-home bronze"></i> Home
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to ='/Products' className='nav-links' onClick={closeMobileMenu}>
                            <i class="fas fa-car Pcolor"></i>    Products
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to ='/services' className='nav-links' onClick={closeMobileMenu}>
                            <i class="fas fa-wrench red"></i>     Services
                        </Link>
                    </li>
                    {/* <li className='nav-item'>
                        <Link to ='/Orders' className='nav-links' onClick={closeMobileMenu}>
                        <i class="fas fa-file-upload yellow"></i>My Orders
                        </Link>
                    </li> */}
                    {/* <li className='nav-item'>
                        <Link to ='/Upload' className='nav-links' onClick={closeMobileMenu}>
                        <i class="fas fa-file-upload"></i>Upload
                        </Link>
                    </li> */}
                    <li className='nav-item'>
                        <Link to ={!user && '/Login'} className='nav-link-login' onClick={closeMobileMenu}>
                  
                            <div onClick={handleAuthentication} className="header__option">
                                <div className="header__option1">
                                    <i class="fas fa-user-lock orange"/>               
                                </div>

                                 <div className="header__option2">
                                    <div className="header__optionLineOne">
                                        {!user? 'Guest': user.email}
                                    </div>
                                    <div className="header__optionLineTwo">
                                        {user ? 'Sign Out' : 'Sign In'}
                                    </div>
                                </div>
                        </div>
                        </Link> </li>

                        <li className="nav-item">
                        <Link to ="/checkout" className='nav-link-cart nav-link-cart' onClick={closeMobileMenu}>
                        <div className="header__optionBasket">
                                <i className="fas fa-shopping-cart fa-lg "></i>
                            <span className="header__optionLineTwo header__basketCount">{!user? '0' : basket?.length }</span>
                            </div>
                            </Link>
                            </li>
                   
                    
                  
                   
                </ul>
                </div>
                {/* {button && <Button buttonStyle='btn--outline'>Sign Up</Button>} */}
           
        </>

    )
}

export default Navbar
