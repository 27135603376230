import React,{useState, useEffect} from 'react';
import '../css/Mufflers.css';
import {firebaseApp, db} from '../firebase';


function Mufflers() {
    const [Products, setProducts] = React.useState([])
    const [display_items, setDisplay_items] = useState('All')
   
   
   
   
   // useEffect(() => {

    //     //this fetches all items.
    //     setDisplay_items('ALL')
    //       console.log("showing everything")
    //        db.collection('Lights').onSnapshot(ProductsCollection =>
    //         setProducts(ProductsCollection.docs.map(doc => doc.data())
    //         ))
               
    //    }, [])
    return (
        <div className="M_container">
            <h1>Mufflers</h1>
            
        </div>
    )
}

export default Mufflers
