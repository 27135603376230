import React from 'react';
import './css/Product_Section.css' ;
import ProductItem from './ProductItem';
import rim from '../images/rim.png';
import './css/ProductItem.css';
import tyre from '../images/tyre.png';
import acc from '../images/acc.png';
import blue_rim from '../images/blue_rim.png';
import lights from '../images/lights.png';
import gauge from '../images/gauge.png';
import intake from '../images/intake.png';
import audio from '../images/audio.png';
import parts from '../images/parts.png';
import bag from '../images/bride.jpg';
import { Link } from 'react-router-dom';




function Product_Section() {
    return (
        <div className="Product__options">
            
            <h1>Products</h1>
            
            <div className="item_display">
               <ul>
                   <Link to='/Rims' className="pi-link" >
                <li className="product_cat">
                    <img src ={rim} />
                    <p>Rims</p>
                </li>

                </Link>

                {/* <Link to='/Audio' className="pi-link"> */}
                <Link to='/Audio' onClick={ (event) => event.preventDefault() } className="pi-link">
                
                <li className="product_cat_ua">
                    <img src ={audio} />
                    <p>Audio</p>
                </li>
                </Link>
                
                <Link to='/Mufflers' onClick={ (event) => event.preventDefault() } className="pi-link">
                <li className="product_cat_ua" >
                    <img src ={acc} title="Coming soon!"/>
                    <p>Mufflers</p>
                </li>
                </Link>
                
                <br/>
                
                </ul>
               <ul>
                   <Link to='/Tyres' className="pi-link">
               <li className="product_cat">
                    <img src ={tyre} />
                    <p>Tyres</p>
                </li>
                   </Link>
               <Link to='/Gauges' onClick={ (event) => event.preventDefault() } className="pi-link">
                <li className="product_cat_ua">
                    <img src ={gauge} />
                    <p>Gauges</p>
                </li>
               </Link>
               <Link to='/Intake' onClick={ (event) => event.preventDefault() } className="pi-link">
                <li className="product_cat_ua">
                    <img src ={intake} />
                    <p>Intake</p>
                </li>
               </Link>
                <br/>
                
                </ul>
                <ul>
                    <Link to='/Lights' onClick={ (event) => event.preventDefault() } className="pi-link">
                <li className="product_cat_ua">
                    <img src ={lights} />
                    <p>Lights</p>
                </li>
                    </Link>
                <Link to='/VehicleParts' onClick={ (event) => event.preventDefault() } className="pi-link">
                   <li className="product_cat_ua">
                    <img src ={parts} />
                    <p>Vehicle Parts</p>
                </li>
                </Link>
                <Link to='/Misc' onClick={ (event) => event.preventDefault() } className="pi-link">
                   <li className="product_cat_ua">
                    <img src ={bag} />
                    <p>Miscellaneous</p>
                </li>
                </Link> 
                    
                </ul>
                
            </div>
            <center><p>~ Items that are not clickable would be updated soon! ~</p></center>

            {/* <div className="P__container">
            <div className="P__wrapper">
                <ul className="P__items">
                    <ProductItem
                    src={rims}
                    text="RIMS"
                    path='/Rims'
                    
                    />
                    <ProductItem
                    src={tyre}
                    text="TYRES"
                    path='/Tyres'/>
                    <ProductItem

                    src={acc}
                    text="Accessories"
                    path='/CarAccessories'/>
                 
                </ul>
                </div>
        </div> */}
        <div className="display">
        <img  src={blue_rim}></img>

        </div>
        </div>
        
    )
}

export default Product_Section
