import React, {useState,useEffect,useRef, Component } from 'react'
import styled from 'styled-components'
import {MdClose, MdOutlineClosedCaptionDisabled} from 'react-icons/md'
import img from '../WbB_LOGO.png'
import { useStateValue } from '../StateProvider'
import {ToastContainer, toast} from 'react-toastify';
import {useSpring, animated} from 'react-spring';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";


const Background = styled.div`

@media (max-width:730px){
    height:100vh;
    background: rgba(0,0,0,0.8);
}

top:10px;
z-index: 9999;
width:100%;
height:100vh;
background: rgba(0,0,0,0.8);
position:fixed;
display: flex;
justify-content: center;
align-items: center;
left:0%;
overflow:auto;
 



`

const ModalWrapper = styled.div`
@media (max-width:730px){
    top:100px;
    flex-direction:column;
    height:auto;
    overflow:auto;
   
}
width: 900px;
height:auto;
box-shadow:0 5px 16px rgba(0,0,0,0.2);
background:#fff;
color:#000;
display: flex;
flex-direction:row;
position: relative;
z-index: 10;
border-radius: 10px;
padding:15px;
overflow:auto;


`

const ModalLeft= styled.div`
display:flex;
justify-content:center;

`

const ModalRight= styled.div`
display:flex;
justify-content:center;
align-items:center;
padding:10px;
margin-left:10px;
border-left: 1px solid grey;
width: 50%;
@media (max-width:730px){
    width:100%;
    margin-top:10px;
    margin-left:0px;
    border-top: 1px solid grey;
    border-left:0px;
}
`

const ModalImg = styled.img`
width:450px;
height:auto;
border-radius:10px 0 0 10px;
background:#fff;

@media(max-width:730px){
    width:350px;
}

`
const ModalContent = styled.div`

display: flex;
flex-direction: column;
justify-content: center;
align-items:center;
line-height: 1.8;
color:#141414;


h1{
    margin-bottom: 1rem;
    text-decoration: underline DASHED 2px grey;
}

p{
    
    text-decoration-thickness: 3px ;
}

button{
    padding: 10px 24px;
    background: #141414;
    color: #fff;
    border: none;
}
.info-modal{
    display:flex;

}

.info-modal1{
    display:flex;
    border-bottom: 1px solid grey;
}

`;

const CloseModalButton =  styled(MdClose)`
    @media (max-width:730px){
        position: relative;
        left:20px;
    }

cursor: pointer;
 position: absolute;
// top: 20px;
 right:20px;
width:32px;
height:32px;
padding:0;
z-index: 10;



`;




export const Modal = ({showModal, setShowModal, props}) => {
    const [{basket,user},dispatch] = useStateValue();
    

    const modalRef = useRef();
    
const closeModal = e => {
    if(modalRef.current === e.target){
        setShowModal(false);
    }
}

    const animation =useSpring({
        config:
        {
            duration:300
        },
        opacity: showModal ? 1:0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`
    })
    
    
    const notify = () => toast.success('✓   Item Added to cart!' , {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        } );
    const error = () => toast.warning('⚠️ Please Login to continue' , {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        } );

   
        const [toggleState, setToggleState] = useState(1);

        const toggleTab = (index) => {
            setToggleState(index);
        }; 
        // toggleTab(2);
        const [stock, setStock] = useState("Instock")
        const [color, setColor]= useState()
        const [color1, setColor1]= useState()
        const [cursor, setCursor]= useState()
        const [disable, setDisable] = useState()
       
        useEffect(() => {
             if(props.amount<"4"){
                 setStock("Out of Stock");
                 setDisable("true")
                 setColor('red')
                 setColor1('grey')
                 setCursor('not-allowed')
             }else{
                setDisable("false")
                setStock("Instock");
                setColor('green')
                setColor1('rgb(43, 114, 247)')
                setCursor('pointer')


             }
        }, [props.amount])

        
    const addToBasket = () => {
        if(user){
        if(disable=="false"){
         dispatch({
             type: "ADD_TO_BASKET",
             item:{
                 id:props.id,
                 boxnumber: props.boxnumber,
                 brand: props.brand,
                 image:props.src,
                 color:props.color,
                 price:props.price,
                 size:props.size,
                 offset:props.offset,
                 bolt_pattern:props.bolt_pattern,
                 amount:props.amount

             
                 
             },
           });
           notify();
         }
         else if(disable=="true"){
             console.log("Sorry, Item is out of stock")
         }
        }
        else{
            error();
        }
       
     }
    console.log();
    return(
        <>
        {showModal ?
        <Background ref={modalRef} onClick={closeModal}>
            {/* <animated.div style={animation}> */}
            <ModalWrapper showModal={showModal }>
               
                <ModalLeft>
                <CloseModalButton aria-label='Close Modal' onClick={() => setShowModal(prev => !prev)}/>
                    <TransformWrapper>
                        <TransformComponent>
                           <ModalImg src={props.src}/>
                        </TransformComponent>
                    </TransformWrapper>
                    
                </ModalLeft>

                <ModalRight>

                  <ModalContent>
                    <center>
                    <h1> {props.brand}</h1>
                    <div className="info-modal">
                        <p>Size : </p>  <h4> {props.size}</h4>
                        </div>
                    <div className="info-modal">
                        <p>Bolt Pattern : </p><h5> {props.bolt_pattern}</h5>
                        </div>
                    <div className="info-modal">
                        <p>Offset : </p>  <h5> {props.offset}</h5>
                        </div>
                    
                    <div className="info-modal1" >
                   <p>Color : </p> <h5> {props.color}</h5>
                   </div>
                        <br/>           
                        <br/>

                  <i class="fas fa-cart-plus fa-3x add_to_cart" style={{ color: color1, cursor: cursor }} onClick={() => { addToBasket(); } }></i>
                        <p>Add to wishlist</p>

                    </center>
                </ModalContent>   
                
                </ModalRight>
                

               
            </ModalWrapper>
            {/* </animated.div> */}
        </Background>
             :null }
        
        </>
    )
}