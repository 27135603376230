import React from 'react'
import {Link} from 'react-router-dom';
import './css/ProductItem.css';

function ProductItem(props) {
    return (
        <>
            <li className ='P__item'>
                <Link className='P__item__link' to={props.path}>
                    <figure className='P__item__pic-wrap'>
                        <img className='P__item__img'
                        src={props.src} 
                        alt="Rim image" />
                    </figure>
                    <div className="P__item__info">
                        <h5 className='P__item__text'>{props.text}</h5>
                    </div>
                </Link>
            </li>
        </>
    )
}

export default ProductItem;