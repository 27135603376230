import React from 'react'
import '../App.css'
import './css/LandingPage.css'
import {Button} from './Button';
import {Carousel} from 'react-bootstrap';
import rimpic1 from '../images/rimpic1.jpg';
import rimpic2 from '../images/rimpic2.jpg';
import rimpic3 from '../images/rimpic3.jpg';
import rimpic4 from '../images/rimpic4.jpg';
import rimpic5 from '../images/rimpic5.jpg';
import 'animate.css';

// import brands from '../images/tyre-brands.png';
import bg from '../images/main_bg.png'

function LandingPage() {

    return (
    <div className="hero">
             <Carousel>
     <Carousel.Item interval={3500}>
    <img
      className="d-block "
      src={rimpic3}
      alt="Third slide"
    />
    <Carousel.Caption>
    
      <div class="animated-title">
          <div class="text-top">
            <div>
              <span>Give Your ride</span>
              <span>A new LOOK</span>
            </div>
          </div>
          <div class="text-bottom">
            <div>Today!</div>
          </div>
    </div>
   
     
    </Carousel.Caption> 
  </Carousel.Item> 

  <Carousel.Item interval={3500}>
    <img
      className="d-block "
      src={rimpic1}
      alt="second slide"
    />
    <Carousel.Caption>
    <div className="slide2_text">
        <h3 className="animate__animated animate__wobble" >#Adventure Awaits!</h3>
      </div>
    </Carousel.Caption>
  </Carousel.Item>
 <Carousel.Item interval={3500}>
   <img
      className="d-block  "
      src={rimpic2}
      alt="Second slide"
    />
    <Carousel.Caption>
      <div className="third_slide">
      <a href="/Tyres"><h1  className="animate__animated animate__rubberBand">Tyres For Your Every Need!</h1></a>
      </div>
    </Carousel.Caption>
  </Carousel.Item>
  
  <Carousel.Item interval={3500}>
    <img
      className="d-block "
      src={rimpic4}
      alt="Fourth slide"
    />
    <Carousel.Caption>
      <h3 className="fitment">#FITMENT</h3>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item interval={3500}>
    <img
      className="d-block "
      src={rimpic5}
      alt="Fourth slide"
    />
    <Carousel.Caption>
            <div className="animate__animated animate__zoomInDown">
      <div className="a">
      <h1 >#Rim_King</h1>
        </div>
           </div> 
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>
</div>
    )
}

export default LandingPage
