import React from 'react'
import {Link} from 'react-router-dom';
import './css/CardItem.css';
// import './css/Rims.css';
import { useStateValue } from './StateProvider';
import {useState, useEffect} from "react";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { auth } from './firebase';
import { Modal } from '../components/pages/Modal';
import { GlobalStyle } from '../components/pages/globalStyles';
// import img1 from '../images/bg1.jpg'


function CardItem(props) {
    const [showModal, setShowModal]= useState(false);

    const openModal = () => {
        setShowModal(prev => !prev);
        
        // console.log(props.brand)
    }
    const [{basket},dispatch] = useStateValue();
  
    const notify = () => toast.success('✓   Item Added to cart!' , {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        } );
    const error = () => toast.warning('⚠️ Please Login to continue' , {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        } );

   
        const [toggleState, setToggleState] = useState(1);

        const toggleTab = (index) => {
            setToggleState(index);
        }; 
        // toggleTab(2);
        const [stock, setStock] = useState("Instock")
        const [color, setColor]= useState()
        const [color1, setColor1]= useState()
        const [cursor, setCursor]= useState()
        const amount=props.amount
        const [disable, setDisable] = useState()
       
        useEffect(() => {
             if(amount<"4"){
                 setStock("Out of Stock");
                 setDisable("true")
                 setColor('red')
                 setColor1('grey')
                 setCursor('not-allowed')
             }else{
                setDisable("false")
                setStock("Instock");
                setColor('green')
                setColor1('rgb(43, 114, 247)')
                setCursor('pointer')


             }
        }, [amount])

        

    // const addToBasket = () => {
    //    if(disable=="false"){
    //     dispatch({
    //         type: "ADD_TO_BASKET",
    //         item:{
    //             id:props.id,
    //             name: props.name,
    //             brand: props.brand,
    //             image:props.src,
    //             color:props.color,
    //             price:props.price,
    //             size:props.size,
            
                
    //         },
    //       });
    //       notify();
    //     }
    //     else if(disable=="true"){
    //         console.log("Sorry, Item is out of stock")
    //     }
    // }
    return (
        
            <><center><Modal showModal={showModal} setShowModal={setShowModal } props={props} /><GlobalStyle /></center>
            <div className='cards__item'>
            <div className='cards__item__link' onClick={openModal}>
                <div className='cards__item__pic-wrap'>
                    <img className='cards__item__img'
                        src={props.src}
                        alt="Rim image" />
                </div>
                <h4 className='cards__item__brand'>{props.brand}</h4>
                <p className='cards__item__name'>{props.name}</p>
                <div className="cards__item__info">



                    <div className="price_info">
                        <p className='p1'>Color: </p>
                        <p className='cards__item__color'> {props.color}</p>

                    </div>
                    <div className="price_info">
                        <p className='p1'>Sizes: </p>
                        <p className='cards__item__size'> {props.size}"</p>
                    </div>
                    <div className="price_info">
                        <p className='p1'>Bolt pattern: </p>
                        <p className='cards__item__size'> {props.bolt_pattern}</p>
                    </div>
                    {/* <div className="price_info">
        <p className='cards__item__price'>${props.price} </p>
        <p className='cards__item__set'>/per set(4)</p>
    </div> */}

                    {/* <div className="cr_right">
                        <center>

                            <i class="fas fa-cart-plus fa-2x add_to_cart" style={{ color: color1, cursor: cursor }} onClick={() => { openModal(); } }></i>

                        </center>

                        <p>Add to cart</p>
                    </div> */}
                </div>
                <center>
                    <p style={{ color: color }}>{stock}</p>
                </center>

            </div>

        </div>
         </>
    )
}

export default CardItem;
