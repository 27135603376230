import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reducer, {initialState} from "./components/reducer";
import {StateProvider} from './components/StateProvider';
// import {CookiesProvider} from 'react-cookie';


ReactDOM.render(
  <React.StrictMode>
{/* <CookiesProvider> */}
  <StateProvider initialState={initialState} reducer={reducer}>
      
  <App />

</StateProvider>
{/* </CookiesProvider> */}
 </React.StrictMode>
  ,document.getElementById('root')
);

