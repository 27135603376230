import React, {useState} from 'react';
import '../css/Login.css';
import {auth, } from "../firebase";
import firebase from "firebase";
import {Link, useHistory} from "react-router-dom";
import logo from '../WbB_LOGO.png';
import {FcGoogle} from 'react-icons/fc';
import { FaFacebookSquare} from 'react-icons/fa';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Login() {
    const notify = () => toast.success('✓ Logged in Successfully!' , {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        } );

const history = useHistory();
const [email, setEmail] = useState('');
const [password, setPassword] = useState('');

const signIn = e =>{
    e.preventDefault();
    
    auth
        .signInWithEmailAndPassword(email, password)
        .then(auth => {
            history.push('/')

        })
        .catch(error => alert(error.message))
}

const Google = () =>{
    var provider = new firebase.auth.GoogleAuthProvider();

    firebase.auth().signInWithPopup(provider).then(function(result) {
        var token = result.credential.accessToken;
        var user = result.user;
        notify();
        history.push('/')
    })
    .catch(error => { console.log(error)})
    
}
const Facebook = () =>{
    var fbprovider = new firebase.auth.FacebookAuthProvider();

    firebase
        .auth()
        .signInWithPopup(fbprovider)
        .then((result)=>{
          /**  @type {firebase.auth.OAuthCredential}*/ 
    var credential = result.credential;

    var user = result.user;
    var accessToken = credential.accessToken;
    notify();
    history.push('/')
  })
  .catch(error => { console.log(error)
    
  });
}

const register = e => {
    e.preventDefault();

    auth 
        .createUserWithEmailAndPassword(email, password)
        .then((auth) => {
            //successfully created new user with email and password
            console.log(auth);

            if(auth){
                history.push('/')
            }
        })
        .catch(error => alert(error.message))

    //firebase register
}
    return (
        
        <div className='login'>
  
            <div className="login__container">
                <h1>Sign-in</h1>
                {/* <form>
                    <h5>E-mail</h5>
                    <input type='text' value={email} onChange={e => setEmail(e.target.value)}/>

                    <h5>Password</h5>
                    <input type='password' value={password} onChange={e=> setPassword(e.target.value)}/>
                    
                    <button type='submit' onClick={signIn} className='login__signInBtn'>Sign In</button>
                    <p>
                        By Signing- in you agree to the terms and conditions!
                    </p> 

                    <button onClick={register}
                            className='login__registerButton'>
                            Create your Local Account
                    </button>
                    


                </form> */}
                     
                <div class="google-btn" onClick={Google}>
                <div class="google-icon-wrapper">
                    <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
                </div>

                <p class="btn-text"><b>Sign in with google</b></p>
                {/* </div>
                <div class="google-btn" onClick={Facebook}>
                <div class="google-icon-wrapper">
                <img class="google-icon" src="https://cdn1.iconfinder.com/data/icons/logotypes/32/square-facebook-256.png"/>

                </div> */}

                {/* <p class="btn-text"><b>Login with FaceBook</b></p>*/}
                </div> 
                
            </div><ToastContainer />
        </div>
        
    )
}

export default Login
