import React,{useEffect, useState} from 'react';
import './css/checkoutProduct.css';
import { useStateValue } from './StateProvider';
import tyre_img from './../images/tyre.png'



function CheckoutProduct({id, image,brand, boxnumber, bolt_pattern,offset, size,color,amount, hideButton}) {
    const [{basket}, dispatch] = useStateValue();
    const [stock, setStock] = useState("Instock");
    const [colorr, setColor]= useState();
    const [type, setType] = useState(true);

    const removeFromBasket = () =>{
        //remove fromt the basket
        dispatch({
            type: 'REMOVE_FROM_BASKET',
            id: id,
            
        })
    }
    useEffect(() => {
        if(amount<"4"){
            setStock("Out of Stock");
            setColor('red');            
        }else{

           setStock("Instock");
           setColor('green')
          
        }

   }, [amount])

   useEffect(() => {
    if(boxnumber && color){
        setType(true);
        if(amount<"4"){
            setStock("Out of Stock");
            setColor('red');            
        }else{

           setStock("Instock");
           setColor('green')
          
        }
         
    }else{

       setType(false);
       if(amount<"1"){
        setStock("Out of Stock");
        setColor('red');            
    }else{

       setStock("Instock");
       setColor('green')
      
    }
      
    }

}, [boxnumber])
     return (
        <>
        <div>{type?  <div className="checkoutProduct">
             <img className='checkoutProduct__image' src={image}/>
             <center>
                    <p style={{ color: colorr }}>{stock}</p>
                </center>
             <div className="checkoutProduct__info">
                <div className="name_brand">
                     <p className= 'checkout__item__brand'>{brand}</p>
                    
                    </div> 
                
                 <p className= 'checkout__item__color'>Color: {color}</p>
                 <p className= 'checkout__item__size'>Size: {size}"</p>
                 <p className='checkout__item__size'> Bolt pattern: {bolt_pattern}</p>
                 <p className='checkout__item__size'> Offset: {offset}</p>

<br/>
                 <p className= 'checkout__item__color'> ID: {boxnumber}</p>
{/*              
                 <p className='checkout__item__price'>
                     <small>$</small>
                     <strong>{price}</strong>
                 </p> */}
 
                 {!hideButton && (
                     <button onClick={removeFromBasket}>Remove</button>
                 )}
 
             </div>
         
         </div> 
        
        
        
        
        : <div className="checkoutProduct">
        <img className='checkoutProduct__image' src={tyre_img}/>
        <center>
               <p style={{ color: colorr }}>{stock}</p>
           </center>
        <div className="checkoutProduct__info">
           <div className="name_brand">
                <p className= 'checkout__item__brand'>{brand}</p>
               
               </div> 
          
            <p className= 'checkout__item__size'>Size: {size}"</p>
        
            {!hideButton && (
                <button onClick={removeFromBasket}>Remove</button>
            )}

        </div>
    
    </div>}</div> 

        
 </>
     )
 }
export default CheckoutProduct
