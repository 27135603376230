import React, {useEffect, useState} from 'react';
import '../css/Intake.css';
import {firebaseApp, db} from '../firebase';

function Intake() {
    const [Products, setProducts] = React.useState([])
    const [display_items, setDisplay_items] = useState('All')
   
   
   
   
   // useEffect(() => {

    //     //this fetches all items.
    //     setDisplay_items('ALL')
    //       console.log("showing everything")
    //        db.collection('Lights').onSnapshot(ProductsCollection =>
    //         setProducts(ProductsCollection.docs.map(doc => doc.data())
    //         ))
               
    //    }, [])
    return (
        <div className="Intake_container">
            <h1>Car Intake</h1>
        </div>
    )
}

export default Intake
