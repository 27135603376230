// import '../../App.css';
import '../css/Rims.css';
import '../css/tab.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import firebase from "../firebase";
import { useContext} from "react";
import {ToastContainer, toast} from 'react-toastify';

import benz from "../../images/benz_logo.png";
import bmw from "../../images/bmw_logo.png";
import audi from "../../images/audi_logo.png";
import toyota from "../../images/toyota_logo.png";
import enkei from "../../images/enkei_logo.png";
import konig from "../../images/konig_logo.png";
import vossen from "../../images/vossen_logo.png";
import lexani from "../../images/lexani_logo.png";
import westernwheels from "../../images/westernwheels_logo.jfif";

import {Link} from 'react-router-dom';
import {firebaseApp, db} from '../firebase';
import CardItem from '../CardItem';
import { Modal } from './Modal';
import { GlobalStyle } from './globalStyles';
import LoadingOverlay from 'react-loading-overlay';
import { Collapse } from 'react-bootstrap';
import  Collapsible  from 'react-collapsible';
import {Helmet} from "react-helmet";

// import '../css/CardItem.css';




export default function Rims() {
    const [Products, setProducts] = React.useState([])
    const [color, setColor] = useState('');
    const [brand, setBrand] = useState('');
    const [size, setSize] = useState('');
    const [display_items, setDisplay_items] = useState('All');
    const [lastDoc, setLastDoc] = useState();
    const [listofrims, setListOfRims] = useState([]);
    const [isActive, setActive]=useState(false);

//function to show all product when user clicks "Show all rims" button    

useEffect(() => {
    setActive(true)

    //this fetches all rim items.
    setDisplay_items('Recently Added')
      console.log("showing everything")
        console.log("Loading...")

        setTimeout(() => {
            setActive(false)

           console.log("Complete")
       },3000);

           db.collection('Rims').limit(15).orderBy('Timestamp','desc').get().then((ProductsCollections) => {
            const rims= ProductsCollections.docs.map(doc => doc.data());
            const lastDoc = ProductsCollections.docs[ProductsCollections.docs.length -1];
                setListOfRims(rims);
                     setLastDoc(lastDoc);
           });
          

   }, []);

const fetchAllProducts= async () => {
        setDisplay_items('ALL')
        setActive(true)
        console.log("loading...")

        setTimeout(() => {
            setActive(false)

           console.log("Complete")
       },3000);

        const usersCollection = await db.collection('Rims').limit(15).get()
        setListOfRims(usersCollection.docs.map(doc => {
                return doc.data()
            }))
         
        }
    
const FetchBrand =(brand) => {
    
    setActive(true)
    setDisplay_items(brand)
    console.log("brand filter running")

        console.log("loading...")
    
        setTimeout(() => {
             setActive(false)

            console.log("Complete")
        },3000);
    
        db.collection('Rims').limit(15).where('brand','>=',brand).where("brand", "<=", brand + "\uf8ff").onSnapshot(ProductsCollection =>
            setListOfRims(ProductsCollection.docs.map(doc => doc.data())
            ))
   }

   
   const FetchColor= (color) => {
       //this fetches all rim items for choosen color.
    setDisplay_items(color)
    setActive(true)
    
    console.log("color filter running")
    console.log("Loading...")

    setTimeout(() => {
        setActive(false)

       console.log("Complete")
   },3000);
    
       db.collection('Rims').limit(15).where("color", ">=", color)
       .where("color", "<=", color + "\uf8ff").onSnapshot(ProductsCollection =>
        setListOfRims(ProductsCollection.docs.map(doc => doc.data())
        ))           
   }

   const FetchSize = (size) => {
    //this fetches all rim items for choosen size.
    setDisplay_items(size + '"')
    var size1 = parseInt(size) + 1;
    var sizeafter = size1.toString();
    
    setActive(true)
    
    console.log("Size filter running")
    console.log("Loading...")

    setTimeout(() => {
        setActive(false)

       console.log("Complete")
   },3000);

      console.log("size filter running")
       db.collection('Rims').limit(15).where('size','>=',size ).where('size','<=', sizeafter).onSnapshot(ProductsCollection =>
        setListOfRims(ProductsCollection.docs.map(doc => doc.data())
        ))       
   }

const FetchMore=() => {
   
    setActive(true)
    
    console.log("loading more...");
    console.log("Loading...")

    setTimeout(() => {
        setActive(false)

       console.log("Complete")
   },3000);

    db.collection('Rims').orderBy('Timestamp','desc').startAfter(lastDoc).limit(15).get().then((ProductsCollections) => {
       const rims= ProductsCollections.docs.map(doc => doc.data());
        const lastDoc = ProductsCollections.docs[ProductsCollections.docs.length -1];
        setListOfRims(listofrims => [...listofrims,...rims])
        setProducts(rims);
        setLastDoc(lastDoc);
          
});


}

// code for tab_filters
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    }; 

    const [showModal, setShowModal]= useState(false);

      return (
          
     < div className="rim_container">
     <Helmet>
        <meta charSet="utf-8" />
                <title>Rims</title>
                <meta name="description" content="Rims in Trinidad"></meta>
        </Helmet>
            {/* <h1>Rims</h1> */}
            {/* <Modal showModal={showModal} setShowModal={setShowModal} /><GlobalStyle /> */}
        
        <div className="brand__selection">
            
            <img className="brand_logo" src={benz}></img>
            <img className="brand_logo" src={bmw} ></img>
            <img className="brand_logo" src={westernwheels}></img>
            <img className="brand_logo" src={toyota}></img>
            <img className="brand_logo" src={enkei}></img>
            <img className="brand_logo" src={lexani}></img>
            <img className="brand_logo" src={vossen}></img>
            <img className="brand_logo" src={konig}></img>
            <img className="brand_logo" src={audi}></img>
            <h1>Rims</h1>
            <img className="brand_logo" src={benz}></img>
            <img className="brand_logo" src={bmw} ></img>
            <img className="brand_logo" src={westernwheels}></img>
            <img className="brand_logo" src={toyota}></img>
            <img className="brand_logo" src={enkei}></img>
            <img className="brand_logo" src={lexani}></img>
            <img className="brand_logo" src={vossen}></img>
            <img className="brand_logo" src={konig}></img>
            <img className="brand_logo" src={audi}></img>
        </div>    
            {/* <p>Please select which type of rims you would like</p> */}

        
        <div className="main_display">
       
        <div class="rim_left">
        
            <div className="tab_filter">
            <Collapsible className="filter_btn" trigger="_____________Filter_____________">
               
            <div className="container">
                
                <div className="bloc-tabs">
                    <button
                        className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleTab(1)}>
                        Color
                    </button>
                    
                    <button
                        className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleTab(2)}>
                        Brand
                     </button>
                    <button
                        className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleTab(3)}
                    >
                        Size
                    </button>
                </div>

                <div className="content-tabs">
                    <div className={toggleState === 1 ? "content  active-content" : "content"}>
                        <h2>Filter by color</h2>
                        <hr />
                        <ul className="list_section">
                       
                        <li onClick={() => FetchColor('WHITE')} ><i class="fas fa-square fa-2x white"></i>  White</li>
                    <li onClick={() => FetchColor('GOLD')} ><i class="fas fa-square fa-2x gold"></i>  Gold</li>
                    <li onClick={() => FetchColor('SILVER')} ><i class="fas fa-square fa-2x silver"></i>  Silver</li>
                    <li onClick={() => FetchColor('MATTE SILVER')} ><i class="fas fa-square fa-2x silver"></i> MATTE Silver</li>

                    <li onClick={() => FetchColor('GREEN')} ><i class="fas fa-square fa-2x green"></i>  Green</li>
                    <li onClick={() => FetchColor('BLUE')} ><i class="fas fa-square fa-2x bluee"></i>  Blue</li>
                    <li onClick={() => FetchColor('RED')} ><i class="fas fa-square fa-2x red"></i>  Red</li>
                    <li onClick={() => FetchColor('PINK')} ><i class="fas fa-square fa-2x pink"></i>    Pink</li>
                    <li onClick={() => FetchColor('PURPLE')} ><i class="fas fa-square fa-2x purple"></i>    Purple</li>

                    <li onClick={() => FetchColor('BRONZE')} ><i class="fas fa-square fa-2x bronze"></i>  Bronze</li>
                    <li onClick={() => FetchColor('GREY')} ><i class="fas fa-square fa-2x grey"></i>  Grey</li>
                    <li onClick={() => FetchColor('MATTE GREY')} ><i class="fas fa-square fa-2x grey"></i> MATTE Grey</li>

                    <li onClick={() => FetchColor('GUN METAL')} ><i class="fas fa-square fa-2x grey"></i>  Gun metal</li>
                    <li onClick={() => FetchColor('MATTE GUN METAL')} ><i class="fas fa-square fa-2x grey"></i>  MATTE Gun metal</li>


                    <li onClick={() => FetchColor('YELLOW')} ><i class="fas fa-square fa-2x yellow"></i>  Yellow</li>
                    <li onClick={() => FetchColor('ORANGE')} ><i class="fas fa-square fa-2x orange"></i>  Orange</li>
                    <li onClick={() => FetchColor('CHROME')} ><i class="fas fa-square fa-2x silver"></i>  Chrome</li>

                    <li onClick={() => FetchColor('BLACK')} ><i class="fas fa-square fa-2x black" ></i>  Black</li>
                    <li onClick={() => FetchColor('MATTE BLACK')} ><i class="fas fa-square fa-2x black" ></i> Matte Black</li>
                    <li onClick={() => FetchColor('BLACK CHROME')} ><i class="fas fa-square fa-2x black"></i>  Black Chrome</li>
                    <li onClick={() => FetchColor('BLACK AND SILVER')} ><i class="fas fa-square fa-2x black" ></i>  Black and silver</li>
                    <li onClick={() => FetchColor('BLACK AND CHROME')} ><i class="fas fa-square fa-2x black" ></i>  Black and chrome</li>
                    <li onClick={() => FetchColor('BLACK AND BLUE')} ><i class="fas fa-square fa-2x black" ></i>  Black and blue</li>
                    <li onClick={() => FetchColor('BLACK AND RED')} ><i class="fas fa-square fa-2x black" ></i>  Black and red</li>


                </ul> 
                <center><button className= 'ShowALLButton_sm' onClick={()=>fetchAllProducts()}> View All </button></center>
          
                              </div> 

                <div className={toggleState === 2 ? "content  active-content" : "content"}>
                    <h2>Filter by Brand</h2>
                    <hr />
                    <ul className="list_section_brands_t">
                    <li onClick={() => FetchBrand('A')}>A</li>    <li onClick={() => FetchBrand('B')}>B</li>    <li onClick={() => FetchBrand('C')}>C</li> <li onClick={() => FetchBrand('D')}>D</li> <li onClick={() => FetchBrand('E')}>E</li>
                    <li onClick={() => FetchBrand('F')}>F</li>    <li onClick={() => FetchBrand('G')}>G</li>    <li onClick={() => FetchBrand('H')}>H</li> <li onClick={() => FetchBrand('I')}>I</li> <li onClick={() => FetchBrand('J')}>J</li>
                    <li onClick={() => FetchBrand('K')}>K</li>    <li onClick={() => FetchBrand('L')}>L</li>    <li onClick={() => FetchBrand('M')}>M</li> <li onClick={() => FetchBrand('N')}>N</li> <li onClick={() => FetchBrand('O')}>O</li>
                    <li onClick={() => FetchBrand('P')}>P</li>    <li onClick={() => FetchBrand('Q')}>Q</li>    <li onClick={() => FetchBrand('R')}>R</li> <li onClick={() => FetchBrand('S')}>S</li> <li onClick={() => FetchBrand('T')}>T</li>
                    <li onClick={() => FetchBrand('U')}>U</li>    <li onClick={() => FetchBrand('V')}>V</li>    <li onClick={() => FetchBrand('W')}>W</li> <li onClick={() => FetchBrand('X')}>X</li> <li onClick={() => FetchBrand('Y')}>Y</li>
                    <li onClick={() => FetchBrand('Z')}>Z</li>


                </ul>
                <center><button className= 'ShowALLButton_sm' onClick={()=>fetchAllProducts()}> View All </button></center>
               
                </div>

                <div className={toggleState === 3 ? "content  active-content" : "content"} >
                    <h2>Filter by Size</h2>
                    <hr />
                    <ul className="tab_size">
                    <li onClick={() => FetchSize('14')}>14"</li>    <li onClick={() => FetchSize('15')}>15"</li>     <li onClick={() => FetchSize('16')}>16"</li>
                    <li onClick={() => FetchSize('17')}>17"</li>    <li onClick={() => FetchSize('18')}>18"</li>     <li onClick={() => FetchSize('19')}>19"</li>
                    <li onClick={() => FetchSize('20')}>20"</li>    <li onClick={() => FetchSize('21')}>21"</li>     <li onClick={() => FetchSize('22')}>22"</li>
                    <li onClick={() => FetchSize('23')}>23"</li>    <li onClick={() => FetchSize('24')}>24"</li>     <li onClick={() => FetchSize('25')}>25"</li>
                    <li onClick={() => FetchSize('26')}>26"</li>    <li onClick={() => FetchSize('27')}>27"</li>     <li onClick={() => FetchSize('28')}>28"</li>
                    <li onClick={() => FetchSize('29')}>29"</li>    <li onClick={() => FetchSize('30')}>30"</li>     <li onClick={() => FetchSize('31')}>31"</li>
                    <li onClick={() => FetchSize('32')}>32"</li>    <li onClick={() => FetchSize('33')}>33"</li>
                    
                </ul>
               
                <center><button className= 'ShowALLButton_sm' onClick={()=>fetchAllProducts()}> View All </button></center>
                </div>

    </div>
  
  </div>
  </Collapsible>
            </div>
       

        <div className="brand_filter_section">
            <center><button className= 'ShowALLButton' onClick={()=>fetchAllProducts()}> Show All Rims </button></center>
            
            <h2 className="filter">Filter by Color</h2>
                <ul className="list_section">
                    
                    <li onClick={() => FetchColor('WHITE')} ><i class="fas fa-square fa-1x white"></i>  White</li>
                    <li onClick={() => FetchColor('GOLD')} ><i class="fas fa-square fa-1x gold"></i>  Gold</li>
                    <li onClick={() => FetchColor('SILVER')} ><i class="fas fa-square fa-1x silver"></i>  Silver</li>
                    <li onClick={() => FetchColor('MATTE SILVER')} ><i class="fas fa-square fa-1x silver"></i> MATTE Silver</li>

                    <li onClick={() => FetchColor('GREEN')} ><i class="fas fa-square fa-1x green"></i>  Green</li>
                    <li onClick={() => FetchColor('BLUE')} ><i class="fas fa-square fa-1x bluee"></i>  Blue</li>
                    <li onClick={() => FetchColor('RED')} ><i class="fas fa-square fa-1x red"></i>  Red</li>
                    <li onClick={() => FetchColor('PINK')} ><i class="fas fa-square fa-1x pink"></i>    Pink</li>
                    <li onClick={() => FetchColor('PURPLE')} ><i class="fas fa-square fa-1x purple"></i>    Purple</li>

                    <li onClick={() => FetchColor('BRONZE')} ><i class="fas fa-square fa-1x bronze"></i>  Bronze</li>
                    <li onClick={() => FetchColor('GREY')} ><i class="fas fa-square fa-1x grey"></i>  Grey</li>
                    <li onClick={() => FetchColor('MATTE GREY')} ><i class="fas fa-square fa-1x grey"></i> MATTE Grey</li>

                    <li onClick={() => FetchColor('GUN METAL')} ><i class="fas fa-square fa-1x grey"></i>  Gun metal</li>
                    <li onClick={() => FetchColor('MATTE GUN METAL')} ><i class="fas fa-square fa-1x grey"></i>  MATTE Gun metal</li>


                    <li onClick={() => FetchColor('YELLOW')} ><i class="fas fa-square fa-1x yellow"></i>  Yellow</li>
                    <li onClick={() => FetchColor('ORANGE')} ><i class="fas fa-square fa-1x orange"></i>  Orange</li>
                    <li onClick={() => FetchColor('CHROME')} ><i class="fas fa-square fa-1x silver"></i>  Chrome</li>

                    <li onClick={() => FetchColor('BLACK')} ><i class="fas fa-square fa-1x black" ></i>  Black</li>
                    <li onClick={() => FetchColor('MATTE BLACK')} ><i class="fas fa-square fa-1x black" ></i> Matte Black</li>
                    <li onClick={() => FetchColor('BLACK CHROME')} ><i class="fas fa-square fa-1x black"></i>  Black Chrome</li>
                    <li onClick={() => FetchColor('BLACK AND SILVER')} ><i class="fas fa-square fa-1x black" ></i>  Black and silver</li>
                    <li onClick={() => FetchColor('BLACK AND CHROME')} ><i class="fas fa-square fa-1x black" ></i>  Black and chrome</li>
                    <li onClick={() => FetchColor('BLACK AND BLUE')} ><i class="fas fa-square fa-1x black" ></i>  Black and blue</li>
                    <li onClick={() => FetchColor('BLACK AND RED')} ><i class="fas fa-square fa-1x black" ></i>  Black and red</li>



        
                </ul> 

            <h2 className="filter">Filter by Brand</h2>
                    <ul className="list_section_brands">
                    <li onClick={() => FetchBrand('A')}>A</li>    <li onClick={() => FetchBrand('B')}>B</li>    <li onClick={() => FetchBrand('C')}>C</li> <li onClick={() => FetchBrand('D')}>D</li> <li onClick={() => FetchBrand('E')}>E</li>
                    <li onClick={() => FetchBrand('F')}>F</li>    <li onClick={() => FetchBrand('G')}>G</li>    <li onClick={() => FetchBrand('H')}>H</li> <li onClick={() => FetchBrand('I')}>I</li> <li onClick={() => FetchBrand('J')}>J</li>
                    <li onClick={() => FetchBrand('K')}>K</li>    <li onClick={() => FetchBrand('L')}>L</li>    <li onClick={() => FetchBrand('M')}>M</li> <li onClick={() => FetchBrand('N')}>N</li> <li onClick={() => FetchBrand('O')}>O</li>
                    <li onClick={() => FetchBrand('P')}>P</li>    <li onClick={() => FetchBrand('Q')}>Q</li>    <li onClick={() => FetchBrand('R')}>R</li> <li onClick={() => FetchBrand('S')}>S</li> <li onClick={() => FetchBrand('T')}>T</li>
                    <li onClick={() => FetchBrand('U')}>U</li>    <li onClick={() => FetchBrand('V')}>V</li>    <li onClick={() => FetchBrand('W')}>W</li> <li onClick={() => FetchBrand('X')}>X</li> <li onClick={() => FetchBrand('Y')}>Y</li>
                    <li onClick={() => FetchBrand('Z')}>Z</li>


                    
                 </ul>
               
              
           
            <h2 className="filter">Filter by Size</h2>
                <ul className="list_section_size">
                <li onClick={() => FetchSize('14')}>14"</li>    <li onClick={() => FetchSize('15')}>15"</li>     <li onClick={() => FetchSize('16')}>16"</li>
                    <li onClick={() => FetchSize('17')}>17"</li>    <li onClick={() => FetchSize('18')}>18"</li>     <li onClick={() => FetchSize('19')}>19"</li>
                    <li onClick={() => FetchSize('20')}>20"</li>    <li onClick={() => FetchSize('21')}>21"</li>     <li onClick={() => FetchSize('22')}>22"</li>
                    <li onClick={() => FetchSize('23')}>23"</li>    <li onClick={() => FetchSize('24')}>24"</li>     <li onClick={() => FetchSize('25')}>25"</li>
                    <li onClick={() => FetchSize('26')}>26"</li>    <li onClick={() => FetchSize('27')}>27"</li>     <li onClick={() => FetchSize('28')}>28"</li>
                    <li onClick={() => FetchSize('29')}>29"</li>    <li onClick={() => FetchSize('30')}>30"</li>     <li onClick={() => FetchSize('31')}>31"</li>
                    <li onClick={() => FetchSize('32')}>32"</li>    <li onClick={() => FetchSize('33')}>33"</li>
                    
                </ul>
        
            </div>

        </div>

        <div className="rim_right">
      

   <h2 className="display_items">Showing {display_items} Rims</h2>
            <div >
     
                <ul className="rim_display">
                                 
                    {listofrims.map((Product) => {
                        
                        return (
                                
                                   <li className="list" >
                                       
                                        <LoadingOverlay
                                    active={isActive}
                                    spinner
                                    text='Loading...'
                                    >
                                        <CardItem
                                            id={Product.id}
                                            src={Product.image}
                                            boxnumber={Product.boxnumber}
                                            color={Product.color}
                                            size={Product.size}
                                            brand={Product.brand}
                                            price={Product.price}
                                            amount={Product.amount}
                                            bolt_pattern={Product.bolt_pattern}
                                            offset={Product.offset}
                                            
                                          />  
                                                              
                                    </LoadingOverlay>
                                   </li>
                                     
        
                                );
                         })}
                          
            </ul>
    
            <center><button class= "Finish_btn" onClick={FetchMore}>More</button></center>
            
            </div>

        </div>
        </div>
        
   
            
        <ToastContainer />
        
     </div>
            
            
            
        
    );
};

