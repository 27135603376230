import React, { useEffect, useState } from 'react'
import Tabs from '../Tabs'
import '../css/Lights.css'
import {firebaseApp, db} from '../firebase';





function Lights() {
    const [Products, setProducts] = React.useState([])
    const [display_items, setDisplay_items] = useState('All')
   
   
   
   
    // useEffect(() => {

    //     //this fetches all items.
    //     setDisplay_items('ALL')
    //       console.log("showing everything")
    //        db.collection('Lights').onSnapshot(ProductsCollection =>
    //         setProducts(ProductsCollection.docs.map(doc => doc.data())
    //         ))
               
    //    }, [])
    
    return (
        <div className="Light_container">
            <h1>Auto Lights</h1>
            
            
        </div>
    );
}

export default Lights
