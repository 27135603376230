import React from 'react';
import '../css/services.css';
// import ServiceItem from '../ServiceItem';
import appointment from '../../images/appointment.png';
import balancing from '../../images/balancing.png';
import tyre_fitting from '../../images/tiremachine.png';
import { Link } from 'react-router-dom';
import Service_section from '../Service_section';



//SERVICES
//RIM PAINTING
//RIM REPAIRS

function Services() {
    return (
        <>
             <Service_section/>
<div className="app_set" id="Appointment">
            <embed  src="https://wheelsbybolo.setmore.com/"/> 
</div>
    
    
       
        </>
    )
}

export default Services
