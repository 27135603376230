import React from 'react';
import {Link} from 'react-router-dom';
import './css/CardItem.css';
import './css/Tyres.css';
import { useStateValue } from './StateProvider';
import {useState, useEffect} from "react";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function TyreItem(props) {
    const [{basket, user},dispatch] = useStateValue();
  
    const notify = () => toast.success('✓   Item Added to cart!' , {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        } );
    const error = () => toast.warning('⚠️ Please Login to continue' , {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        } );

   
        const [toggleState, setToggleState] = useState(1);

        const toggleTab = (index) => {
            setToggleState(index);
        }; 
        // toggleTab(2);
        const [stock, setStock] = useState("Instock")
        const [color, setColor]= useState()
        const [color1, setColor1]= useState()
        const [cursor, setCursor]= useState()
        const amount=props.amount
        const [disable, setDisable] = useState()
       
        useEffect(() => {
             if(amount<"1"){
                 setStock("Out of Stock");
                 setDisable("true")
                 setColor('red')
                 setColor1('grey')
                 setCursor('not-allowed')
             }else{
                setDisable("false")
                setStock("Instock");
                setColor('green')
                setColor1('rgb(43, 114, 247)')
                setCursor('pointer')


             }
        }, [amount])

        const addToBasket = () => {
            if(user){
            if(disable=="false"){
             dispatch({
                 type: "ADD_TO_BASKET",
                 item:{
                     id:props.id,
                     brand: props.brand,
                     price:props.price,
                     size:props.size,
                     amount:props.amount
    
                 
                     
                 },
               });
               notify();
             }
             else if(disable=="true"){
                 console.log("Sorry, Item is out of stock")
             }
            }
            else{
                error();
            }
           
         }    

   
    
    return (
        
            <div className ='cards__item'>
                <div className='cards__item__link' to={props.path}>
                    {/* <div className='cards__item__pic-wrap'>
                        <img className='cards__item__img'
                        src={props.src} 
                        alt="Rim image" />
                    </div> */}
                    <h4 className='cards__item__brand'>{props.brand}</h4>
                                <p className='cards__item__name'>{props.name}</p>
                    <div className="cards__item__info_tyre">
                        
                        <div className="cr_left_tyre">
                      
                         <div className="price_info">
                            <p className='p1'>Size: </p>
                        <h1 className="tyre_size"> {props.size}</h1>
                        </div>  
                        <div className="price_info">
                            <p className='p1'>Beading: {props.beading} </p>
                        
                        </div> 
                        
                        {/* <div className="price_info">
                            <p className='cards__item__price'>${props.price} </p>
                            <p className='cards__item__set'>/per tyre</p>
                        </div> */}
                   </div>
                   <div className="cr_right_tyre">
                           <i class="fas fa-cart-plus fa-2x add_to_cart" style={{color: color1, cursor:cursor}} onClick={() => { addToBasket()}}></i>
                           <p>Add to cart</p>
                   </div>

                    </div>
                    <center>
                        <p style={{color: color}} >{stock}</p>
                        </center>

                </div>
                
            </div>
        
    )
}
export default TyreItem
