import React from 'react'
import '../components/css/services.css';
// import ServiceItem from '../ServiceItem';
import appointment from '../images/appointment.png';
import balancing from '../images/balancing.png';
import tyre_fitting from '../images/tiremachine.png';
import { Link } from 'react-router-dom';


function Service_section() {
    return (
        <div className="Service__options">
            
            <h1>Services</h1>
            <p >Welcome to our site, our team is filled with a warm and welcoming staff, ready to help in any way we can.</p>
            {/* <p>Due to the covid-19 pandemic, we are now taking appointments to ensure maximum safety for both our customers and Staff.</p> */}
            
            <div className="S__container">
            <div className="S__wrapper">
                <ul className="S__items">
                    <div className="service-item">
                        <div className="service-left">
                            <img className="service-img" src={appointment}/>

                        </div>
                        <div className="service-right">

                        <a href="/Services#Appointment"><h1 className="s_header">Make an Appoinment</h1></a>
                      
                        
                        
                            <p className="s_text">Due to the covid-19 pandemic, we are now taking appointments to ensure maximum safety for both our customers and Staff.</p>
                        
                        </div>

                    </div>
                    <div className="service-item2">
                        <div className="service-right">
                            <Link>
                            <h1 className="s_header">Rim and Tyre Installation</h1>
                            </Link>
                            <p className="s_text">
                                From big to small, we install them all!
                            </p>
                        </div>

                        <div className="service-left">
                            <img className="service-img2" src={tyre_fitting}/>
                        </div>

                    </div>
                    <div className="service-item">
                        <div className="service-left">
                            <img className="service-img" src={balancing}/>

                        </div>
                        <div className="service-right">
                            <Link><h1 className="s_header"> Speed Balancing</h1></Link>
                            <p className="s_text" >Tyres shaking at high speeds? we can help.</p>
                        </div>

                    </div>
                   
                </ul>
                </div>
        </div>
     
        </div>
        
    )
}

export default Service_section
