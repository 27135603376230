import '../../App.css'
import React from 'react';
import LandingPage from '../LandingPage'
import Footer from '../Footer';
import Product_Section from '../Product_Section'
// import Reviews from '../Reviews';
import Services from './Services'
import '../css/Home.css'
import Service_section from '../Service_section';
import {Helmet} from "react-helmet";
// import InstagramFeed from 'react-ig-feed';
// import 'react-ig-feed/dist/index.css';


function Home(){
    return(
        <>
        <Helmet>
        <meta charSet="utf-8" />
                <title>Wheels by bolo</title>
        </Helmet> 
            <LandingPage />
            
            <Product_Section/>
            
            <Service_section/>
<div className="Area">
        <div className="Map_Area"> 
        <h1>Location</h1>
            <iframe className="Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d773.5859652552028!2d-61.394342057479726!3d10.190416090955626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c35891fcc8db7a5%3A0x3f720a8c528b9959!2sWheels%20By%20Bolo!5e0!3m2!1sen!2stt!4v1621876970916!5m2!1sen!2stt"  allowfullscreen=""
            loading="lazy"></iframe>

        </div>
        {/* <div className="Gallery_Area"> 
            <h1>This is the gallery Area</h1>
            <InstagramFeed token="" counter="10"/>
        </div> */}

</div>            
        </>
    );
}


export default Home;